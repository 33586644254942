export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [0,4];

export const dictionary = {
		"/(website)": [~25,[5]],
		"/(app)/account": [19,[2]],
		"/(app)/account/profile": [20,[2]],
		"/(app)/(portfolio)/assets": [6,[2,3]],
		"/(app)/(portfolio)/cash": [7,[2,3]],
		"/(app)/(portfolio)/crypto": [8,[2,3]],
		"/(app)/dashboard": [21,[2]],
		"/(website)/error": [26,[5]],
		"/(auth)/login": [~23],
		"/(auth)/logout": [24],
		"/(app)/(portfolio)/options": [~9,[2,3]],
		"/(app)/(portfolio)/options/new": [10,[2,3]],
		"/(app)/(portfolio)/options/trades": [~12,[2,3]],
		"/(app)/(portfolio)/options/trade/[id]": [~11,[2,3]],
		"/(website)/privacy": [27,[5]],
		"/(app)/(portfolio)/stocks": [~13,[2,3,4]],
		"/(app)/(portfolio)/stocks/generate": [14,[2,3,4]],
		"/(app)/(portfolio)/stocks/new": [15,[2,3,4]],
		"/(app)/(portfolio)/stocks/trades": [~17,[2,3,4]],
		"/(app)/(portfolio)/stocks/trade/[id]": [~16,[2,3,4]],
		"/(app)/(portfolio)/super": [18,[2,3]],
		"/(app)/symbol/[symbol]": [22,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';